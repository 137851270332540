import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useStoreContext } from '../../factorys/useStoreContext';
import { IdentityContext } from '../App/identity.context';
import { AuthingContext } from '../App/authing.context';
import { useInterval } from 'react-use';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GetChatroomMessagesDocument,
  GetChatroomMessagesQuery,
  GetClassUserDataDocument,
  UserIdentity,
} from '../../services/graphql/types/graphql';
import cx from 'classnames';
import { format } from 'date-fns';
import ToolTip from 'rc-tooltip';

import EmojiIconSVG from './emoji-icon.svg?react';
import ImageIconSVG from './image-icon.svg?react';
import EmojiPicker, { Categories, SuggestionMode } from 'emoji-picker-react';
import { MediaUpload } from '../../components/MediaUpload';

const UserListItem: React.FC<{
  name: string;
  avatarUrl?: string;
  badge?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ name, avatarUrl, badge, onClick }) => {
  return (
    <div
      key={`chatroom-user-${name}`}
      onClick={onClick}
      className="flex flex-col items-center relative"
    >
      {badge && +badge > 0 && (
        <div className="absolute top-0 right-0 size-4 rounded-full bg-red-500 text-xs flex items-center justify-center text-white">
          {badge}
        </div>
      )}
      <div
        className={cx('rounded-full size-12', {
          'bg-slate-400': !avatarUrl,
        })}
      >
        {avatarUrl && <img className="object-cover" src={avatarUrl} alt="" />}
      </div>
      <div className="mt-2 text-[13px]">{name}</div>
    </div>
  );
};

export const Chatroom = () => {
  const { currentClassId, currentCourseId } = useStoreContext(IdentityContext);
  const { user: currentUser } = useStoreContext(AuthingContext);
  const [isChatOnline, setChatOnline] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [textareaData, setTextareaData] = useState<string>('');

  const { data: classUserData, loading: loadingClassUserData } = useQuery(
    GetClassUserDataDocument,
    {
      variables: {
        classId: currentClassId,
      },
      skip: !currentClassId,
    },
  );

  const [getChatroomMessage, { data: chatroomData, loading: loadingChatroom }] = useLazyQuery(
    GetChatroomMessagesDocument,
  );

  const refMessageScrollContainer = useRef<HTMLDivElement | null>(null);

  const [selectedChatroom, selectChatroom] = useState<string>('all');
  const [newReceivedChatMessage, setNewReceivedChatMessage] = useState<
    GetChatroomMessagesQuery['chatroom']['messages']
  >([]);
  const [newMessageNotification, setNewMessageNotification] = useState(new Map<string, number>([]));
  const [onlineUsers, setOnlineUsers] = useState(new Set());

  const chatroomMessage = useMemo(() => {
    return [...(chatroomData?.chatroom?.messages ?? []), ...newReceivedChatMessage];
  }, [chatroomData, newReceivedChatMessage]);

  const chatroomTitle = useMemo(() => {
    if (selectedChatroom === 'all') {
      return '所有人';
    }

    return classUserData?.class.classUsers.find((user) => user.userId === selectedChatroom)?.user
      .name;
  }, [selectedChatroom]);
  const selectedChatroomId = useMemo(() => {
    if (selectedChatroom === 'all') {
      return `${currentClassId}:${currentCourseId}`;
    } else {
      return [selectedChatroom, currentUser.externalId].sort().join(':');
    }
  }, [selectedChatroom, currentClassId, currentCourseId]);

  useEffect(
    () => console.info('selectedChatroomId change:', selectedChatroomId),
    [selectedChatroomId],
  );

  useEffect(() => {
    console.info('selectedChatroom', selectedChatroom);
    if (selectedChatroom === 'all') {
      getChatroomMessage({
        fetchPolicy: 'no-cache',
        variables: {
          chatroomId: `${currentClassId}:${currentCourseId}`,
        },
      });
    } else {
      getChatroomMessage({
        fetchPolicy: 'no-cache',
        variables: {
          chatroomId: selectedChatroomId,
        },
      });
    }
    setNewReceivedChatMessage([]);
  }, [selectedChatroom]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (refMessageScrollContainer.current) {
        refMessageScrollContainer.current.scrollTop =
          refMessageScrollContainer.current.scrollHeight;
        // console.info('scroll to bottom');
      }
    }, 10);
  }, [chatroomMessage]);

  const handleSendImageMessage = ({ url }: any) => {
    if (!socket) {
      return;
    }

    socket.emit(
      'chat_message',
      {
        senderId: currentUser.externalId,
        chatroomId: selectedChatroomId,
        type: 'image',
        content: url,
      },
      (val: any) => {
        setNewReceivedChatMessage((prev) => [...prev, val]);
      },
    );
  };

  const handleSendTextMessage = () => {
    if (!socket) {
      return;
    }

    socket.emit(
      'chat_message',
      {
        senderId: currentUser.externalId,
        chatroomId: selectedChatroomId,
        type: 'text',
        content: textareaData,
      },
      (val: any) => {
        setNewReceivedChatMessage((prev) => [...prev, val]);
      },
    );

    setTextareaData('');
  };

  const sendHeartbeat = () => {
    if (socket) {
      socket.emit('heartbeat', { userId: currentUser.externalId });
    }
  };

  const handleSwitchChatTarget =
    (target: string): React.MouseEventHandler<HTMLDivElement> =>
    (e) => {
      selectChatroom(target);
    };

  const handleTextareaKeyPress: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendTextMessage();
    }
  };

  useEffect(() => {
    if (newMessageNotification.has(selectedChatroomId)) {
      console.info('to be clear', selectedChatroomId);

      setNewMessageNotification((prev) => {
        prev.delete(selectedChatroomId);
        const newPrev = new Map(prev);
        return newPrev;
      });
    }
  }, [selectedChatroomId]);

  useInterval(sendHeartbeat, 5000);

  // useEffect(() => console.info(newMessageNotification), [newMessageNotification]);

  useEffect(() => {
    if (!currentUser || !currentClassId || !currentCourseId) {
      return;
    }

    const socket = io('/', {
      autoConnect: true,
      transports: ['websocket'],
      path: '/chatroom',
      query: {
        classId: currentClassId,
        courseId: currentCourseId,
        userId: currentUser.externalId,
      },
      auth: {
        token: localStorage.getItem('_authing_token'),
      },
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
      setSocket(null);
    };
  }, [currentUser, currentClassId, currentCourseId]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('connect', function () {
      setChatOnline(true);
    });
    socket.on('user_online', function (data) {
      console.info('user_online', data.userId);
      setOnlineUsers((prev) => new Set(prev).add(data.userId));
    });
    socket.on('user_offline', function (data) {
      console.info('user_offline', data.userId);
      setOnlineUsers((prev) => new Set(Array.from(prev).filter((x) => x !== data.userId)));
    });
    socket.on('welcome', function (data) {
      console.info('welcome:', data);
      setOnlineUsers(new Set(data.online));
    });

    return () => {
      socket.off('connect');
      socket.off('user_online');
      socket.off('user_offline');
      socket.off('welcome');
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('chat_message', (data) => {
      console.info('received chat message: ', data);
      console.info('selectedChatroomId: ', selectedChatroomId);
      if (data.chatroomId === selectedChatroomId) {
        setNewReceivedChatMessage((prev) => [...prev, data]);
      } else {
        setNewMessageNotification((prev) => {
          const currentNotificationCount = prev.get(data.chatroomId);
          if (!currentNotificationCount) {
            return new Map(prev).set(data.chatroomId, 1);
          } else {
            return new Map(prev).set(data.chatroomId, currentNotificationCount + 1);
          }
        });
      }
    });

    return () => {
      socket.off('chat_message');
    };
  }, [socket, selectedChatroomId]);

  return (
    <div className="w-full h-full flex">
      <div className="flex-1 flex flex-col bg-white">
        <div className="w-full pl-6 h-12 flex items-center text-base font-semibold">
          {chatroomTitle}
        </div>
        <div ref={refMessageScrollContainer} className="flex-1 bg-[#f5f4f0] p-3 overflow-auto">
          {chatroomMessage.map((m) => {
            return (
              <div
                key={`messages-${m.id}`}
                className={cx('flex p-2 gap-2', {
                  'flex-row-reverse': m.fromUser.userId === currentUser.externalId,
                })}
              >
                <div
                  className={cx('rounded-full size-9', {
                    'bg-slate-400': !m.fromUser.avatarUrl,
                  })}
                >
                  <img src={m.fromUser.avatarUrl ?? ''} alt="" />
                </div>
                <div
                  className={cx('', {
                    'justify-items-end': m.fromUser.userId === currentUser.externalId,
                  })}
                >
                  <div>{m.fromUser.name}</div>
                  {m.messageType === 'text' && (
                    <div className="p-3 rounded-[8px] bg-white mt-1 text-black max-w-[300px]">
                      {m.message}
                    </div>
                  )}
                  {m.messageType === 'image' && (
                    <div className="p-3 rounded-[8px] bg-white mt-1 text-black max-w-[300px] max-h-[300px]">
                      <img src={m.message} alt="" />
                    </div>
                  )}
                  <div className="text-xs text-gray-400">
                    {format(m.createAt, 'yyyy-MM-dd HH:mm:ss')}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="h-52 flex flex-col">
          <div className="relative h-9 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.25)] flex justify-end px-4 gap-5 items-center">
            <div className="relative">
              <ToolTip
                placement="top"
                trigger="click"
                showArrow={false}
                overlay={
                  <EmojiPicker
                    searchDisabled={true}
                    suggestedEmojisMode={SuggestionMode.FREQUENT}
                    onEmojiClick={(e) => {
                      setTextareaData((prev) => prev + e.emoji);
                    }}
                    previewConfig={{ showPreview: false }}
                    categories={[
                      {
                        category: Categories.SUGGESTED,
                        name: '常用',
                      },
                      {
                        category: Categories.SMILEYS_PEOPLE,
                        name: '',
                      },
                      {
                        category: Categories.ANIMALS_NATURE,
                        name: '',
                      },
                      {
                        category: Categories.FOOD_DRINK,
                        name: '',
                      },
                      {
                        category: Categories.TRAVEL_PLACES,
                        name: '',
                      },
                      {
                        category: Categories.ACTIVITIES,
                        name: '',
                      },
                      {
                        category: Categories.OBJECTS,
                        name: '',
                      },
                      {
                        category: Categories.SYMBOLS,
                        name: '',
                      },
                    ]}
                  ></EmojiPicker>
                }
              >
                <EmojiIconSVG></EmojiIconSVG>
              </ToolTip>
            </div>
            <MediaUpload onSuccess={handleSendImageMessage}>
              <ImageIconSVG></ImageIconSVG>
            </MediaUpload>
          </div>
          <div className="flex-1">
            <textarea
              value={textareaData}
              onChange={(e) => e.target.value.length <= 140 && setTextareaData(e.target.value)}
              onKeyDown={handleTextareaKeyPress}
              name=""
              className="w-full h-full p-2"
              id=""
            ></textarea>
          </div>
          <div className="w-full h-sull flex gap-4 justify-end">
            <div>{textareaData.length} / 140</div>
            <button onClick={handleSendTextMessage} className="px-4 bg-slate-300">
              发送
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white overflow-y-auto w-24 h-full py-6 gap-4 shadow-[-2px_0px_2px_0px_rgba(0,0,0,0.25)] flex flex-col items-center">
        <UserListItem
          key={`userlist-all`}
          name="班级群"
          onClick={handleSwitchChatTarget('all')}
          badge={newMessageNotification.get(`${currentClassId}:${currentCourseId}`)?.toString()}
        ></UserListItem>
        {classUserData?.class.classUsers
          .filter((user) => user.userId !== currentUser?.externalId)
          .filter((user) => onlineUsers?.has(user.userId))
          .map((user) => {
            return (
              <UserListItem
                key={`userlist-${user.userId}`}
                name={user.user.name}
                onClick={handleSwitchChatTarget(user.userId)}
                badge={newMessageNotification
                  .get([user.userId, currentUser.externalId].sort().join(':'))
                  ?.toString()}
              ></UserListItem>
            );
          })}
        <div className="bg-slate-200 h-[1px] w-16 flex-shrink-0"></div>
        {classUserData?.class.classUsers
          .filter((user) => user.userId !== currentUser?.externalId)
          .filter((user) => !onlineUsers?.has(user.userId))
          .map((user) => {
            return (
              <UserListItem
                key={`userlist-${user.userId}`}
                name={user.user.name}
                onClick={handleSwitchChatTarget(user.userId)}
                badge={newMessageNotification
                  .get([user.userId, currentUser.externalId].sort().join(':'))
                  ?.toString()}
              ></UserListItem>
            );
          })}
      </div>
    </div>
  );
};
