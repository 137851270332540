import React, { useEffect, useState } from 'react';
import styles from '../style.module.scss';
import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import Upload, { UploadProps } from 'rc-upload';
import { UploadMediaDocument } from '../../services/graphql/types/graphql';
import { RcFile, UploadRequestOption } from 'rc-upload/lib/interface';
// 没导包 yarn add image-blob-reduce
// import ImageBlobReducer from 'image-blob-reduce'

const MAX_IMAGE_SIZE = 8 * 1024 * 1024; // 8MB
const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'];

interface IToolImageValue {
  mediaId: string;
  url: string;
}

interface IMediaUploadProps {
  onSuccess?: (response: Record<string, unknown>, file: RcFile, xhr: XMLHttpRequest) => void;
  children: React.ReactNode;
}

export const MediaUpload: React.FC<IMediaUploadProps> = ({ children, onSuccess }) => {
  const [uploadImg] = useMutation(UploadMediaDocument);

  // 上传前图片检测
  const beforeUpload: UploadProps['beforeUpload'] = (file) => {
    if (!file) {
      alert('上传不可为空');
      return false; // 阻止上传
    }
    const isValidType = IMAGE_TYPES.includes(file.type);
    if (!isValidType || file.size > MAX_IMAGE_SIZE) {
      alert('图片必须小于8MB且为有效图片类型');
      return false; // 阻止上传
    }
    return true; // 允许上传
  };

  const handleUpload = ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }: UploadRequestOption) => {
    try {
      const blob = new Blob([file], { type: 'Image' });

      uploadImg({
        variables: { file: { file: blob } },
      })
        .then(({ data }) => {
          // console.log('图片上传回传数据', data);
          onSuccess?.(data?.createMedia);
        })
        .catch(onError);

      return {
        abort() {
          console.log('upload progress is aborted.');
        },
      };
    } catch (err) {
      alert('图片上传失败');
      // throw new Error(err as string);
    }
  };

  return (
    <Upload
      accept="image/*;capture=camera"
      customRequest={handleUpload}
      beforeUpload={beforeUpload}
      onSuccess={onSuccess}
    >
      {children}
    </Upload>
  );
};
