import React, { useCallback, useState, useEffect } from 'react';
import { AliRTSPusher, RtsClient } from 'aliyun-rts-pusher';
import VideoPlayer from '@Components/VideoPlayer';
import WithWatermark from '@Components/WithWatermark';
import WithCameraChecker from '@Components/WithCameraChecker';
import {
  HomePageCourseLessonFragment,
  UpdateUserLessonReplyWatchTimeDocument,
} from 'src/services/graphql/types/graphql';
import classNames from 'classnames';
import { isNumber, throttle } from 'lodash';
import { useMutation } from '@apollo/client';

import './styles.scss';

const CUSTOM_VIDEO_ID = 'customVideoId';
const SELF_VIDEO_CONTAINER_ID = 'selfVideoContainerId';

let pushClient: RtsClient;

export const LessonReplayPage: React.FC<{
  selectedLesson: HomePageCourseLessonFragment;
  watermark: string;
}> = ({ selectedLesson, watermark = 'watermark' }) => {
  const { watchUrl, pushUrl, watchTimeSec } = selectedLesson?.selfReplayWatch || {};
  const [hasCameraAccess, setHasCameraAccess] = useState<boolean>(false);
  const [isPushStreamReady, setIsPushStreamReady] = useState<boolean>(false);
  const [updateUserLessonReplyWatchTime] = useMutation(UpdateUserLessonReplyWatchTimeDocument);
  const [hasEndedLocally, setHasEnddedLocally] = useState<boolean>(false);

  const beginPushStream = async () => {
    console.log('推流初始化');
    // 创建推流实例
    pushClient = AliRTSPusher.createClient();
    // 监听错误事件
    pushClient.on('error', (err) => {
      console.log(err.errorCode);
    });

    pushClient.setRenderView(SELF_VIDEO_CONTAINER_ID);

    // 如果希望将本地预览静音（推出去的流不会静音）
    // videoEl.muted = true;
    pushClient.setVideoQuality('720p_1');

    await pushClient.startCamera();
    console.info('摄像头已启动');
    await pushClient.startMicrophone();
    console.info('麦克风已启动');
    setHasCameraAccess(true);

    console.info('push url: ', pushUrl);
    await pushClient.startPush(pushUrl!);

    setIsPushStreamReady(true);

    console.log('推流成功');
  };

  const cleanupMedia = () => {
    pushClient?.stopPush();
    pushClient?.stopCamera();
    pushClient?.stopMicrophone();
    pushClient?.dispose();
  };

  /**
   * https://help.aliyun.com/zh/live/user-guide/integration-of-web-rts-sdk-for-stream-ingest
   */
  useEffect(() => {
    beginPushStream();
    return () => cleanupMedia();
  }, []);

  useEffect(() => {
    if ((watchTimeSec && watchTimeSec === -1) || hasEndedLocally) {
      /** 播放已结束，别再推流了 */
      cleanupMedia();
    }
  }, [watchTimeSec, hasEndedLocally]);

  /** 更新上次播放时间 */
  const debounceUpdateWatchTime = useCallback(
    throttle((seconds: number) => {
      if ((watchTimeSec && watchTimeSec === -1) || hasEndedLocally || seconds <= 0) {
        /** 播放已结束，别再更新了 */
        return;
      }

      /**
       * hasEndedLocally是异步触发，
       * 此处可能会再给后端发送-1请求后，
       * 又重新发送updateUserLessonReplyWatchTime时长，
       * 导致前端需要重新打开页面才能结束
       * 所以，此处判断一下当前播放秒数是否已经达到最后一秒
       * */
      const totalDuration =
        (document.getElementById(CUSTOM_VIDEO_ID) as HTMLVideoElement)?.duration || 0;
      if (seconds >= totalDuration) {
        return;
      }

      updateUserLessonReplyWatchTime({
        variables: {
          lessonId: selectedLesson.lessonId,
          time: Math.floor(seconds || 0),
        },
      });
    }, 5000),
    [selectedLesson, watchTimeSec, hasEndedLocally],
  );

  const handleEnded = () => {
    setHasEnddedLocally(true);
    updateUserLessonReplyWatchTime({
      variables: {
        lessonId: selectedLesson.lessonId,
        time: -1,
      },
    });
  };

  if (!isNumber(watchTimeSec)) {
    return <div className="bg-white rounded-xl p-24 w-128">未能获取上次播放时间</div>;
  }

  if ((isNumber(watchTimeSec) && watchTimeSec < 0) || hasEndedLocally) {
    return <div className="bg-white rounded-xl p-24 w-128">已完成播放</div>;
  }

  return (
    <>
      {/* 摄像头监控  */}
      <WithCameraChecker hasCameraAccess={hasCameraAccess && isPushStreamReady}>
        {/* 水印 */}
        <WithWatermark content={watermark} targetElementId={CUSTOM_VIDEO_ID}>
          {/* 视频播放器 没获取到上次播放时间就播放 */}
          <VideoPlayer
            id={CUSTOM_VIDEO_ID}
            onCurrentPlayTimeChange={debounceUpdateWatchTime}
            videoSourceProps={{
              src: watchUrl!,
            }}
            initPlayTime={watchTimeSec}
            videoProps={{
              onEnded: handleEnded,
            }}
          />
        </WithWatermark>
      </WithCameraChecker>
      <div
        className={classNames(
          'w-64 absolute top-1 left-1 rounded-lg overflow-hidden',
          'selfVideoContainer',
        )}
        id={SELF_VIDEO_CONTAINER_ID}
      />
    </>
  );
};
