import React from 'react';
import './styles.scss';

export const Loader: React.FC<{ fullscreen?: boolean, reason?: string }> = ({ fullscreen = false, reason  }) => {
  if (fullscreen) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-[#2b2b2c]">
        <div id={reason} className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div id={reason} className="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
