import React, {
  DetailedHTMLProps,
  HTMLProps,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MuYiTongXueLogo from '../../assets/muyitongxue-logo--new-black.png';
import { useLockBodyScroll } from 'react-use';
import { useStoreContext } from '../../factorys/useStoreContext';
import cx from 'classnames';
import { format } from 'date-fns';
import { AuthingContext } from '@Containers/App/authing.context';
import {
  GetClassAnnouncementsDocument,
  GetStudentCoursePageDataDocument,
  GetStudentCoursePageDataQuery,
  HomePageArtworkMediaFragmentDoc,
  HomePageCourseLessonFragment,
  HomePageCourseLessonFragmentDoc,
  HomePageLessonAssignmentFragmentDoc,
  HomePageSelfSubmissionFragmentDoc,
  IdentityFragmentDoc,
  SubmissionStatus,
  UpdateUserInfoDocument,
} from 'src/services/graphql/types/graphql';
import ArtworkUpload from '@Components/ArtworkUpload';
import { ArtworkItem } from '@Containers/HomePage/components/ArtworkItem';
import { getFragmentData } from 'src/services/graphql/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { IdentityContext } from '@Containers/App/identity.context';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Loader } from '@Components/Loader';
import { toast } from 'react-toastify';
import CloseButtonSVG from '@Assets/close-button.svg?react';
import PlayIconSVG from '@Assets/play-icon.svg?react';
import PDFIconSVG from '@Assets/pdf-icon.svg?react';
import PPTIconSVG from '@Assets/ppt-icon.svg?react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { PDFDocumentView } from '@Components/PDFDocumentView';

import Tooltip from 'rc-tooltip';

import BadgeBronze from '@Assets/badge-bronze.png';
import BadgeFullPresent from '@Assets/badge-full-present.png';
import Div100vh from 'react-div-100vh';
import { Transcript } from '@Containers/Transcript';

import { Clock } from '@Components/Clock';
import { LessonReplayPage } from '../LessonReplayPage';
import { CloseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Chatroom } from '../Chatroom';
import { MediaUpload } from '../../components/MediaUpload';

const CourseNameBadge: React.FC<{ name: string; level: string } & HTMLProps<HTMLDivElement>> = ({
  name,
  level,
  ...divProps
}) => {
  return (
    <div {...divProps} className="flex rounded-2xl font-semibold">
      <div className="w-[30px] h-[30px] rounded-full bg-[#E7380D] flex justify-center items-center text-[11px] text-white z-10">
        {level}
      </div>
      <div className="text-[16px] flex items-center pl-6 pr-4 bg-[#ECE9E2] -ml-4 rounded-r-full">
        {name}
      </div>
    </div>
  );
};

export const StudentCoursePage = () => {
  const { user, role, logout } = useContext(AuthingContext);
  const {
    currentClassId,
    currentSchoolId,
    currentIdentity,
    currentCourseId,
    availableCourses,
    currentCourse,
    setCurrentCourseId,
    userIdentities,
  } = useStoreContext(IdentityContext);

  const refClassMeetingContainer = useRef<HTMLDivElement | null>(null);

  const [clockScale, setClockScale] = useState(1);
  useEffect(() => {
    setTimeout(() => {
      if (!refClassMeetingContainer.current) {
        return;
      }
      console.info('clock size change');

      const { height } = refClassMeetingContainer.current.getBoundingClientRect();

      const scale = (height * 0.7) / 300;

      setClockScale(scale);
    }, 300);
  }, [refClassMeetingContainer]);

  // const {
  //   state: { selectedLesson },
  //   memos: { StudentCoursePageData, getStudentCoursePageData },
  //   dispatch,
  // } = useStoreContext(StudentCoursePageStoreContext);
  const [
    getStudentCoursePageData,
    { loading: loadingStudentCoursePageData, error, data: StudentCoursePageData },
  ] = useLazyQuery(GetStudentCoursePageDataDocument);

  const { loading: loadingClassAnnouncementsData, data: classAnnouncementsData } = useQuery(
    GetClassAnnouncementsDocument,
    {
      variables: {
        classId: currentClassId,
      },
      skip: !currentClassId,
    },
  );

  // useEffect(
  //   () => console.info('StudentCoursePageData updated', StudentCoursePageData),
  //   [StudentCoursePageData],
  // );

  useEffect(() => {
    if (currentSchoolId && currentClassId && role) {
      getStudentCoursePageData({
        variables: {
          courseId: currentCourseId,
        },
      }).catch(console.error);
    }
  }, [currentCourseId, role, getStudentCoursePageData]);

  const [selectedLessonIndex, selectLesson] = useState<number | null>(0);

  const [showChatroom, setShowChatroom] = useState(false);

  const currentLessonIndex = useMemo(() => {
    if (!StudentCoursePageData) {
      return null;
    }

    const now = new Date();
    const lessons = StudentCoursePageData.course.lessons;
    const currentLessonIndex = lessons.findIndex((l) => {
      const lesson = getFragmentData(HomePageCourseLessonFragmentDoc, l);
      const lessonDate = new Date(lesson.lessonSchedule[0].lessonDate);

      return lessonDate > now;
    });

    if (currentLessonIndex > lessons.length || currentLessonIndex < 0) {
      return lessons.length - 1;
    }

    return currentLessonIndex ?? 2;
  }, [StudentCoursePageData]);

  useEffect(() => {
    selectLesson(currentLessonIndex);
  }, [currentLessonIndex]);

  const studentNumber = useMemo(() => {
    return userIdentities.schools
      .map((s) =>
        s.classes.map((cl) => getFragmentData(IdentityFragmentDoc, cl.selfIdentity).studentNumber),
      )
      .flat(Infinity)
      .filter((sn: any) => !!sn.trim())[0];
  }, [userIdentities]);

  const selectedLesson = useMemo(() => {
    if (
      !StudentCoursePageData ||
      selectedLessonIndex === null ||
      selectedLessonIndex === undefined
    ) {
      return null;
    }
    return getFragmentData(
      HomePageCourseLessonFragmentDoc,
      StudentCoursePageData.course.lessons[selectedLessonIndex],
    );
  }, [selectedLessonIndex, StudentCoursePageData]);
  // useEffect(
  //   () => console.info('selectedLessonIndex updated', selectedLessonIndex),
  //   [selectedLessonIndex],
  // );
  // useEffect(() => console.info('selectedLesson updated', selectedLesson), [selectedLesson]);

  const [currentHover, setCurrentHover] = useState<any>(null);
  // useEffect(() => console.info('currentHover updated', currentHover), [currentHover]);

  const [currentSelectedFunction, setSelectedFunction] = useState<any>({ type: 'tools' });

  const currentSelectedAssignment = useMemo(() => {
    if (currentSelectedFunction.type !== 'assignment' || !currentSelectedFunction.id) {
      return null;
    }

    const assignment = selectedLesson?.assignments
      .map((a) => getFragmentData(HomePageLessonAssignmentFragmentDoc, a))
      .find(({ assignmentId }) => assignmentId === currentSelectedFunction.id);
    // console.info('currentSelectedAssignment updated', assignment);
    return assignment;
  }, [currentSelectedFunction, selectedLesson, StudentCoursePageData]);

  const finishedAssignment = useMemo(() => {
    if (!currentSelectedAssignment) {
      return [];
    }
    return (
      selectedLesson?.assignments.filter((a) => {
        const assignment = getFragmentData(HomePageLessonAssignmentFragmentDoc, a);
        const selfSubmission = getFragmentData(
          HomePageSelfSubmissionFragmentDoc,
          assignment.selfSubmission,
        );

        return (selfSubmission?.artworks[0]?.medias.length ?? 0) >= assignment.minQuantity;
      }) ?? []
    );
  }, [currentSelectedAssignment]);

  // console.info('finishedAssignment', finishedAssignment);

  const submissionArtworkMedias = useMemo(() => {
    const selfSubmission = getFragmentData(
      HomePageSelfSubmissionFragmentDoc,
      currentSelectedAssignment?.selfSubmission,
    );
    const list = selfSubmission?.artworks[0]?.medias;

    // console.info('submissionArtworkMedias updated', list);
    return list;
  }, [currentSelectedAssignment, StudentCoursePageData]);

  const [isSwitchingCourse, setIsSwitchingCourse] = useState(false);

  const canUpload = useMemo(() => {
    if (!currentSelectedAssignment) {
      return false;
    }

    const selfSubmission = getFragmentData(
      HomePageSelfSubmissionFragmentDoc,
      currentSelectedAssignment.selfSubmission,
    );

    return !selfSubmission || selfSubmission?.submissionStatus !== SubmissionStatus.Completed;
  }, [currentSelectedAssignment]);
  // useEffect(() => console.info('canUpload', canUpload), [canUpload]);

  const refScrollContainer = useRef<HTMLDivElement | null>(null);
  const refMainSection = useRef<HTMLDivElement | null>(null);
  useLockBodyScroll(true, refScrollContainer);
  // useEffect(() => {
  //   function onClickPage() {
  //     setIsSwitchingCourse(false);
  //   };
  //   document.addEventListener('click', onClickPage);
  //   return () => {
  //     document.removeEventListener('click', onClickPage);
  //   }
  // }, []);

  const [showReplayOverlay, setShowReplayOverlay] = useState(false);

  const handleSelectLesson = (index: number) => {
    selectLesson(index);
    setSelectedFunction({ type: 'tools' });
  };

  const currentSelectedTool = useMemo(() => {
    if (currentSelectedFunction.type === 'tool-detail' && currentSelectedFunction.toolId) {
      return selectedLesson?.lessonTools.find(
        ({ tool }) => tool.toolId === currentSelectedFunction.toolId,
      );
    }
  }, [currentSelectedFunction]);

  // useEffect(
  //   () => console.info('StudentCoursePageData', StudentCoursePageData),
  //   [StudentCoursePageData],
  // );
  // useEffect(
  //   () => console.info('loading', loadingStudentCoursePageData),
  //   [loadingStudentCoursePageData],
  // );

  // useEffect(() => console.info('currentIdentity', currentIdentity), [currentIdentity]);
  useEffect(() => console.info('select lesson: ', selectedLesson?.name), [selectedLesson]);
  // useEffect(() => console.info('currentLessonIndex', currentLessonIndex), [currentLessonIndex]);

  const [
    updateUsertInfo,
    { loading: loadingUpdateUserInfo, data: updateUserInfoData, error: updateUserInfoError },
  ] = useMutation(UpdateUserInfoDocument);
  const handleAvatarUploadSuccess = (uploadResponse: any) => {
    updateUsertInfo({
      variables: {
        avatarUrl: uploadResponse.url,
      },
    });
  };

  if (
    !StudentCoursePageData ||
    loadingStudentCoursePageData ||
    !currentIdentity ||
    !selectedLesson ||
    currentLessonIndex === null
  ) {
    return <Loader fullscreen />;
  }

  return (
    <Div100vh
      ref={refMainSection}
      className="grid--student-course-page w-screen h-screen p-4 relative"
      onClick={() => setIsSwitchingCourse(false)}
    >
      <div
        className={cx('absolute top-0 right-0 h-full z-[1000] flex transition-[right]', {
          'right-[-600px]': !showChatroom,
        })}
      >
        <div className="h-full w-9 bg-transparent flex flex-col">
          <div
            onClick={() => setShowChatroom(!showChatroom)}
            className="py-6 w-full flex flex-col items-center justify-center bg-slate-500 mt-auto mb-[200px] rounded-l-lg"
          >
            <span className="vertical-lr text-lg text-white">聊天室</span>
            <span className="text-lg text-white">{showChatroom ? '>' : '<'}</span>
          </div>
        </div>
        <div className="h-full w-[600px] shadow-[-4px_0px_4px_0px_rgba(0,0,0,0.25)]">
          <Chatroom></Chatroom>
        </div>
      </div>
      {/* 个人信息 */}
      <div className="befor-course-info p-3 flex flex-col justify-between bg-white relative">
        <div className="w-full h-full flex flex-col">
          {/* 姓名学号头像 */}
          <div className="flex items-center">
            <div className="relative w-12 h-12 rounded-full">
              <div className="absolute top-0 right-0 size-full bg-black hover:opacity-80 opacity-0 rounded-full text-white flex justify-center items-center leading-4 transition-opacity">
                <MediaUpload onSuccess={handleAvatarUploadSuccess}>
                  修改<br></br>头像
                </MediaUpload>
              </div>
              <img
                className="rounded-full w-12 h-12"
                src={userIdentities.user.avatarUrl ?? user?.photo ?? ''}
                alt=""
              />
            </div>
            <div className="flex flex-col ml-[9px] font-semibold mt-auto">
              <div className="text-[20px]">{user?.nickname ?? user?.name}</div>
              <div className="text-xs text-[#747474]" onClick={logout}>
                {studentNumber ?? ''}
              </div>
            </div>
            {/* <div
              onClick={logout}
              className="text-[1.39vw] text-[#797979] ml-[1.389vw] rounded-full w-[3.47vw] h-[3.47vw] bg-white flex items-center justify-center"
            >
              退出登录
            </div> */}
          </div>
          {/* 小红花 */}
          <div className="flex mt-auto items-center gap-x-2 grayscale">
            {/* <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div className='w-4 h-4 rounded-full bg-[#DFDBD1]'></div>
            <div></div> */}
          </div>
          {/* 徽章 */}
          <div className="flex mt-2 items-center gap-x-2">
            {userIdentities?.user.userBadges.map((badge) => {
              return (
                <img
                  key={`badge-${badge.badgeId}`}
                  className="size-8"
                  src={badge.badge.imageUrl}
                  alt=""
                />
              );
            })}
            {/* <div className='w-8 h-8 rounded-full bg-[#DFDBD1]'></div> */}
            {/* <div className='w-8 h-8 rounded-full bg-[#DFDBD1]'></div> */}
            {/* <div className='w-8 h-8 rounded-full bg-[#DFDBD1]'></div> */}
            {/* <div></div> */}
          </div>
        </div>
      </div>
      {/* 工具材料 */}
      <div
        className="befor-course-info bg-white relative overflow-hidden"
        onClick={() => setSelectedFunction({ type: 'tools' })}
      >
        <div className="w-full h-full">
          <Swiper
            className="w-full h-full"
            modules={[Autoplay]}
            loop
            direction="vertical"
            autoplay={{ delay: 3500 }}
            slidesPerView={1}
          >
            {selectedLesson.lessonTools.map(({ tool }) => {
              return (
                <SwiperSlide key={`tools-slider-${tool.toolId}`}>
                  <img className="w-full h-full object-cover" src={tool.media?.url ?? ''} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-0 hover:opacity-100 transition-opacity z-40">
          <p className="relative p-3 text-white z-50">工具材料</p>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div>
        </div>
      </div>
      {/* 课程资料 */}
      <div
        className="befor-course-info bg-white relative  overflow-hidden col-span-2"
        onClick={() => setSelectedFunction({ type: 'text' })}
      >
        <div className="w-full h-full">
          <Swiper
            className="w-full h-full"
            // modules={[Autoplay]}
            loop
            // autoplay={{ delay: 3000 }}
            slidesPerView={1}
          >
            {selectedLesson.lessonExtras.map((lessonExtras) => {
              return (
                <SwiperSlide key={`tools-slider-${lessonExtras.id}`}>
                  <img
                    className="w-full h-full object-cover"
                    src={lessonExtras.coverUrl ?? ''}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-0 hover:opacity-100 transition-opacity z-40">
          <p className="relative p-3 text-white z-50">课程资料</p>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div>
        </div>
      </div>
      {/* 授课教师 */}
      <div
        className="befor-course-info bg-white relative overflow-hidden"
        onClick={() => setSelectedFunction({ type: 'faculty' })}
      >
        <img className="object-contain" src={selectedLesson.faculty.avatarMedia.url ?? ''} alt="" />
        <div className="absolute top-3 left-3 w-full h-full opacity-0 hover:opacity-100 transition-opacity">
          授课教师
        </div>
      </div>
      {/* Logo */}
      <div className="logo-space flex flex-col items-center justify-center bg-white relative">
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-1/2 h-1/2 ">
            <img className="object-contain" src={MuYiTongXueLogo} alt="" />
          </div>
        </div>
        <div className="flex pb-3 px-2 gap-x-2 w-full">
          <div className="flex items-center h-[30px] w-1/2 bg-[#DFDBD1] rounded-full relative overflow-hidden flex-1">
            <div className="text-[10px] w-[30px] h-full text-white relative z-30 flex-shrink-0 bg-[#DE4D19] flex justify-center items-center rounded-full">
              <div className="">课程</div>
            </div>
            <div className=" flex items-center ml-auto ">
              <div className="text-white text-[14px] ml-auto mr-2 z-40">
                {`${Math.round(
                  (finishedAssignment.length / selectedLesson.assignments.length) * 100,
                )}%`}
              </div>
              <div
                className="absolute top-0 left-0 h-full bg-[#DE4D19] rounded-full z-20 "
                style={{
                  width: `${
                    (finishedAssignment.length / selectedLesson.assignments.length) * 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
          <div className="flex items-center h-[30px] w-1/2 bg-[#DFDBD1] rounded-full relative overflow-hidden flex-1">
            <div className="text-[10px] w-[30px] h-full text-white relative z-30 flex-shrink-0 bg-[#DE4D19] flex justify-center items-center rounded-full">
              <div className="">作业</div>
            </div>
            <div className=" flex items-center ml-auto rounded-full">
              <div className="text-white text-[14px] ml-auto mr-2 z-40">
                {`${Math.round(
                  (finishedAssignment.length / selectedLesson.assignments.length) * 100,
                )}%`}
              </div>
              <div
                className="absolute top-0 left-0 h-full bg-[#DE4D19] rounded-full z-20"
                style={{
                  width: `${
                    (finishedAssignment.length / selectedLesson.assignments.length) * 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="left-side-bar flex flex-col gap-y-3 flex-1">
        {/* 展览 */}
        {StudentCoursePageData.course.exhibitions?.[0] && (
          <a
            href={`/exhibition/${StudentCoursePageData.course.exhibitions[0].exhibitionId}`}
            className="bg-white relative block rounded-[12px] overflow-hidden flex-shrink-0"
          >
            <img src={`${StudentCoursePageData.course.exhibitions?.[0].coverUrl}?123`}></img>
          </a>
        )}
        {/* 课程名 */}
        <div className="course-name bg-white flex p-3 relative">
          <div
            className="flex flex-col w-fit"
            onClick={(e) => {
              setIsSwitchingCourse(true);
              e.stopPropagation();
            }}
          >
            <CourseNameBadge
              name={StudentCoursePageData.course.name}
              level={StudentCoursePageData.course.courseLevel}
            ></CourseNameBadge>
            <div className="flex items-center text-[20px] mt-auto font-semibold">
              09:00～11:00{' '}
              <div className="border-black border rounded-full flex justify-center items-center w-5 h-5 flex-shrink-0 ml-3 text-[13px]">
                {currentCourseId === '755107d8-2967-43e8-8f2f-708f011ce407' && '六'}
                {currentCourseId === 'c33be76d-bef8-44f1-aec0-6ee3273e7106' && '日'}
                {currentCourseId === 'ed708792-7bcb-4284-ba40-6a4fad60b316' && '六'}
                {currentCourseId === '2720c669-b6ca-43b1-ad92-8aa9ffcfdab6' && '日'}
              </div>
            </div>
          </div>
          {isSwitchingCourse && (
            <div className="absolute top-[90%] left-0 w-full bg-white rounded-b-[12px] z-40 px-3 py-8 flex flex-col gap-y-5">
              {availableCourses
                ?.filter(({ course }) => course.courseLevel && course.courseId !== currentCourseId)
                .map(({ course }) => {
                  return (
                    <CourseNameBadge
                      key={`course-name-badge-${course.courseId}`}
                      name={course.name}
                      level={course.courseLevel}
                      onClick={(e) => {
                        setCurrentCourseId(course.courseId);
                        setIsSwitchingCourse(false);
                        e.stopPropagation();
                      }}
                    ></CourseNameBadge>
                  );
                })}
            </div>
          )}
        </div>
        {/* 课程表 */}
        <div className="left-side-menu flex flex-col bg-[#DFDBD1] flex-1 overflow-hidden">
          <div className="flex flex-col flex-1 overflow-auto">
            {StudentCoursePageData.course.lessons.map((lessonFrag, lessonIndex) => {
              const lesson = getFragmentData(HomePageCourseLessonFragmentDoc, lessonFrag);
              const lessonDate = lesson.lessonSchedule[0].lessonDate?.replaceAll('-', '.').slice(2);
              const lessonIsOpen =
                lesson.lessonSchedule[0].isToolsOpen || lesson.lessonSchedule[0].isExtrasOpen;

              return (
                <div
                  className={cx(
                    'flex items-center w-full border-b border-white pl-2 gap-x-3 flex-shrink-0 h-11 text-[14px]',
                    {
                      'text-black': lessonIndex < currentLessonIndex,
                      'text-[#F3382F]': lessonIndex === currentLessonIndex,
                      'text-[#318fcb]': lessonIndex === currentLessonIndex + 1,
                      'text-white': lessonIndex > currentLessonIndex + 1,
                    },
                  )}
                  key={`lesson-list--${lesson.lessonId}`}
                  onClick={() =>
                    lessonIsOpen ? handleSelectLesson(lessonIndex) : toast.info('课程尚未开放')
                  }
                >
                  <div className="vertical-lr text-[10px] flex-shrink-0 w-[10px]">
                    {lessonIndex === currentLessonIndex + 1 && <span>下周</span>}
                    {lessonIndex === currentLessonIndex && <span>本周</span>}
                    {lessonIndex === currentLessonIndex - 1 && <span>过往</span>}
                    {lessonIndex === currentLessonIndex + 2 && <span>将来</span>}
                  </div>
                  <div className="font-semibold font-barlow text-[13px]">{lessonDate}</div>
                  <div className="w-[10px] flex-shrink-0 flex justify-center items-center">
                    <div
                      className={cx('rounded-full w-[3px] h-[3px] bg-white ', {
                        '!bg-[#F3382F] !w-[10px] !h-[10px]':
                          selectedLesson?.lessonId === lesson.lessonId,
                      })}
                    ></div>
                  </div>
                  <div className="text-[13px] whitespace-nowrap overflow-hidden text-ellipsis">
                    {lesson.name}
                  </div>
                </div>
              );
            })}
            {Array(16 - StudentCoursePageData.course.lessons.length)
              .fill(0)
              .map((_, index) => {
                return <div key={`lesson-list-placeholder-${index}`} className="flex-1"></div>;
              })}
          </div>
        </div>
      </div>
      {/* 工作区 */}
      <div
        ref={refScrollContainer}
        className="working-space bg-white px-3 py-3 flex flex-col overflow-hidden"
      >
        <div className="w-full h-full overflow-auto">
          <header className="flex items-center h-[30px]">
            <div className="px-5 py-1 bg-black rounded-full text-[12px] font-bold tracking-wider text-white h-[30px] flex items-center">
              {StudentCoursePageData.course.name}
            </div>
            <div className="ml-3 text-[18px] font-bold tracking-wider">{selectedLesson?.name}</div>
          </header>
          {/* 作业 */}
          {currentSelectedAssignment && (
            <div className="w-full h-full pl-5 pt-5 flex flex-col">
              <div className="text-[22px] font-semibold">{currentSelectedAssignment.name}</div>
              <div className="mt-5 pr-16">{currentSelectedAssignment.description}</div>
              <div className="border-b border-white flex pb-3 mt-6">
                <div className="flex items-center">
                  <div className="text-[12px] leading-4">
                    完成<br></br>日期
                  </div>
                  <span className="text-[32px] ml-3 font-barlow font-bold">
                    {format(new Date(currentSelectedAssignment.dueDate as string), 'M.d')}
                  </span>
                </div>
                <div className="flex items-center ml-7">
                  <div className="text-[12px] leading-4">
                    最低<br></br>数量
                  </div>
                  <span className="text-[32px] ml-3 font-barlow font-bold">
                    {currentSelectedAssignment.minQuantity}
                  </span>
                </div>
                {getFragmentData(
                  HomePageSelfSubmissionFragmentDoc,
                  currentSelectedAssignment?.selfSubmission,
                )?.submissionStatus === SubmissionStatus.Completed && (
                  <div
                    className="flex items-center ml-7"
                    onClick={() =>
                      setSelectedFunction({
                        type: 'Transcript',
                        submissionId: getFragmentData(
                          HomePageSelfSubmissionFragmentDoc,
                          currentSelectedAssignment?.selfSubmission,
                        )?.submissionId,
                      })
                    }
                  >
                    <div className="text-[12px] leading-4">
                      教师<br></br>评价
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-4 xl:grid-cols-5 gap-3 h-full mt-6">
                {submissionArtworkMedias?.map((mediaFrag, artworkIndex) => {
                  const media = getFragmentData(HomePageArtworkMediaFragmentDoc, mediaFrag);

                  return (
                    <div key={media.mediaId} className="aspect-w-1 aspect-h-1 h-0">
                      <ArtworkItem
                        deleteAble={
                          getFragmentData(
                            HomePageSelfSubmissionFragmentDoc,
                            currentSelectedAssignment?.selfSubmission,
                          )?.submissionStatus !== SubmissionStatus.Completed
                        }
                        assignmentId={currentSelectedAssignment.assignmentId}
                        data={media}
                        // onMouseDown={onMouseDown}
                        // onMouseUp={onMouseUp(artworkIndex + 1)}
                      />
                    </div>
                  );
                })}
                {canUpload && (
                  <div key="upload-artwork" className="aspect-w-1 aspect-h-1 h-0">
                    <ArtworkUpload
                      assignmentId={currentSelectedAssignment.assignmentId}
                      refetchQueries={['GetStudentCoursePageData']}
                    ></ArtworkUpload>
                  </div>
                )}
                {5 - (submissionArtworkMedias?.length ?? 0) > 0 &&
                  Array(5 - (submissionArtworkMedias?.length ?? 0))
                    .fill(0)
                    .map((_, index) => {
                      return <div key={`placeholder-${index}`} className="flex-1"></div>;
                    })}
              </div>
            </div>
          )}
          {/* 工具 */}
          {currentSelectedFunction.type === 'tools' && (
            <div className="flex px-5 py-10 w-full h-full">
              <div className="grid grid-cols-3 grid-rows-3 flex-1">
                {selectedLesson.lessonTools.map(({ tool }) => {
                  return (
                    <div className="w-full h-full flex-1" key={`tool-list-${tool.toolId}`}>
                      <img
                        key={`lesson-tools-${selectedLesson.lessonId}-${tool.toolId}`}
                        className={cx('w-full h-full object-cover transition-opacity', {
                          'opacity-50':
                            currentHover?.type === 'tool' && currentHover.id !== tool.toolId,
                          'opacity-100':
                            currentHover?.type === 'tool' && currentHover.id === tool.toolId,
                        })}
                        src={tool.media?.url ?? ''}
                        alt=""
                        onMouseEnter={() => setCurrentHover({ type: 'tool', id: tool.toolId })}
                        onMouseLeave={() => setCurrentHover(null)}
                        onClick={() =>
                          setSelectedFunction({ type: 'tool-detail', toolId: tool.toolId })
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <div className="w-[180px] flex-shrink-0 mt-auto ml-8 flex flex-col">
                {selectedLesson.lessonTools.map((lessonTool) => {
                  return (
                    <div
                      key={`lesson-tools-desc-${selectedLesson.lessonId}-${lessonTool.tool.toolId}`}
                      onMouseEnter={() =>
                        setCurrentHover({ type: 'tool', id: lessonTool.tool.toolId })
                      }
                      onMouseLeave={() => setCurrentHover(null)}
                      onClick={() =>
                        setSelectedFunction({ type: 'tool-detail', toolId: lessonTool.tool.toolId })
                      }
                      className={cx('py-1', {
                        'opacity-100':
                          currentHover?.type === 'tool' &&
                          currentHover.id === lessonTool.tool.toolId,
                        'opacity-50':
                          currentHover?.type === 'tool' &&
                          currentHover.id !== lessonTool.tool.toolId,
                      })}
                    >
                      {lessonTool.tool.name} {lessonTool.quantity} {lessonTool.tool.unit}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* 工具说明 */}
          {currentSelectedFunction.type === 'tool-detail' && currentSelectedTool && (
            <div className="flex w-full h-full items-center px-12">
              <div className="w-2/3 h-4/5 overflow-hidden flex-shrink-0">
                <img
                  className="w-full h-full object-contain"
                  src={currentSelectedTool.tool.media?.url ?? ''}
                  alt=""
                />
              </div>
              <div className="mx-10 ">
                <div className="text-3xl">{currentSelectedTool.tool.name}</div>
                <br />
                <div className="leading-7">{currentSelectedTool.tool.description}</div>
              </div>
            </div>
          )}
          {/* 资料 */}
          {currentSelectedFunction.type === 'text' && (
            <div className="flex flex-col py-10 px-5 w-full h-full">
              <p className="mb-6">{selectedLesson.description}</p>
              {selectedLesson.lessonSchedule[0].isExtrasOpen && (
                <ul className="flex flex-col gap-y-3 mt-auto">
                  {selectedLesson.lessonExtras.map((lessonExtra) => {
                    return (
                      <li
                        key={`lesson-extras-${lessonExtra.id}`}
                        className="cursor-pointer flex items-center w-fit"
                        onClick={() =>
                          setSelectedFunction({
                            type: lessonExtra.courseExtrasType,
                            url: lessonExtra.mediaUrl,
                          })
                        }
                      >
                        <PlayIconSVG className="w-4 h-4 mr-4"></PlayIconSVG>
                        {lessonExtra.caption}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
          {/* 教师 */}
          {currentSelectedFunction.type === 'faculty' && (
            <div className="flex w-full h-full items-start px-12 mt-[72px]">
              <div className="w-1/4 aspect-w-1 aspect-h-1 overflow-hidden flex-shrink-0 rounded-full">
                <img
                  className="  object-cover"
                  src={selectedLesson.faculty.avatarMedia.url}
                  alt=""
                />
              </div>
              <div className="ml-10">
                <div className="text-3xl">{selectedLesson.faculty.name}</div>
                <br />
                <div className="leading-7">{selectedLesson.faculty.description}</div>
              </div>
            </div>
          )}
          {/* 视频播放 */}
          {currentSelectedFunction.type === 'Video' && currentSelectedFunction.url && (
            <div className="flex justify-center w-full h-full items-center pt-4 ">
              <div className="relative h-full">
                <video
                  className="h-full"
                  controls
                  src={currentSelectedFunction.url}
                  autoPlay
                ></video>
                <CloseButtonSVG
                  className="absolute right-4 top-4 w-6 h-6 cursor-pointer"
                  onClick={() => setSelectedFunction({ type: 'text' })}
                />
              </div>
            </div>
          )}
          {/* PDF展示 */}
          {currentSelectedFunction.type === 'PDF' && currentSelectedFunction.url && (
            <div className="absolute h-screen w-screen top-0 left-0 z-10">
              <div
                className="absolute top-0 left-0 w-screen h-screen opacity-80 bg-black z-20"
                onClick={() => setSelectedFunction({ type: 'text' })}
              ></div>
              <div className="absolute z-30 h-screen w-[80vw] top-0 left-1/2 -translate-x-1/2 overflow-hidden">
                <CloseButtonSVG
                  className="w-6 h-6 absolute top-6 left-6 z-30"
                  onClick={() => setSelectedFunction({ type: 'text' })}
                ></CloseButtonSVG>
                <PDFDocumentView url={currentSelectedFunction.url}></PDFDocumentView>
              </div>
            </div>
          )}
          {/* MSDOC展示 */}
          {currentSelectedFunction.type === 'PPT' && currentSelectedFunction.url && (
            <div className="absolute h-screen w-screen top-0 left-0 z-10">
              <div
                className="absolute top-0 left-0 w-screen h-screen opacity-80 bg-black z-20"
                onClick={() => setSelectedFunction({ type: 'text' })}
              ></div>
              <div className="absolute z-30 h-screen w-[80vw] top-0 left-1/2 -translate-x-1/2 overflow-hidden">
                <PlayIconSVG
                  className="w-6 h-6 absolute top-6 left-6 z-30"
                  onClick={() => setSelectedFunction({ type: 'text' })}
                ></PlayIconSVG>
                <iframe
                  className="w-full h-full"
                  id="msdoc-iframe"
                  title="msdoc-iframe"
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                    currentSelectedFunction.url,
                  )}`}
                ></iframe>
              </div>
            </div>
          )}
          {/* 成绩单展示 */}
          {currentSelectedFunction.type === 'Transcript' &&
            currentSelectedFunction.submissionId && (
              <div className="absolute h-screen w-screen top-0 left-0 z-50">
                <div
                  className="absolute top-0 left-0 w-screen h-screen opacity-80 bg-black z-20"
                  onClick={() => setSelectedFunction({ type: 'text' })}
                ></div>
                <div className="absolute z-30 h-screen w-[80vw] top-0 left-1/2 -translate-x-1/2 overflow-hidden">
                  <CloseButtonSVG
                    className="w-6 h-6 absolute top-6 left-6 z-30"
                    onClick={() => setSelectedFunction({ type: 'text' })}
                  ></CloseButtonSVG>
                  <Transcript submissionId={currentSelectedFunction.submissionId}></Transcript>
                </div>
              </div>
            )}
        </div>
      </div>
      {/* 上课 */}
      <div
        ref={refClassMeetingContainer}
        className="course-meeting-link bg-white overflow-hidden flex-grow-0 relative"
      >
        <div className="flex flex-col absolute top-3 left-3">
          <div className="flex items-center">
            <div className="mr-2 text-black">上课</div>
            <svg
              width="15"
              height="18"
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z" fill="#D9D9D9" />
            </svg>
          </div>
        </div>
        <div className="mr-2 text-black absolute right-3 top-3">
          会议密码：{selectedLesson.lessonSchedule[0].meetingPassword}
        </div>
        <a
          className="w-full h-full flex-1"
          href={selectedLesson.lessonSchedule[0].meetingLink}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[46%]"
            // @ts-ignore
            style={{ '--tw-scale-x': clockScale, '--tw-scale-y': clockScale }}
          >
            <Clock></Clock>
          </div>
        </a>
      </div>
      {/* 申请回放 */}
      <div className="bg-white relative">
        <div className="flex items-center pt-3 pl-3 ">
          <div className="mr-2 text-black">课程回放</div>
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z" fill="#D9D9D9" />
          </svg>
          <div className="h-full w-full flex items-center justify-center absolute top-0 left-0">
            {selectedLesson.selfReplayWatch &&
              selectedLesson.selfReplayWatch.watchTimeSec !== -1 && (
                <div onClick={() => setShowReplayOverlay(true)}>
                  <PlayCircleOutlined className="text-6xl" />
                </div>
              )}
            {selectedLesson.selfReplayWatch &&
              selectedLesson.selfReplayWatch.watchTimeSec === -1 && (
                <div onClick={() => setShowReplayOverlay(true)}>
                  <p className="text-2xl">已观看回放</p>
                </div>
              )}
            {!selectedLesson.selfReplayWatch && (
              <div onClick={() => setShowReplayOverlay(true)}>
                <p className="text-xl text-center">
                  请向班主任老师
                  <br />
                  申请观看回放
                </p>
              </div>
            )}
          </div>
        </div>
        {showReplayOverlay && (
          <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black/[.54]">
            <CloseOutlined
              size={14}
              className="absolute right-12 top-12 bg-white p-4 rounded-full"
              onClick={() => setShowReplayOverlay(false)}
            />
            <div
              className="bg-transparent w-[calc(100%-128px)] h-[calc(100%-128px)] px-16 py-24 flex items-center justify-center rounded-xl"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <LessonReplayPage
                selectedLesson={selectedLesson}
                watermark={`${user?.nickname ?? user?.name}-${studentNumber}`}
              />
            </div>
          </div>
        )}
      </div>
      {/* 课堂作业 */}
      <div className="bg-white p-3 flex flex-col col-span-2 overflow-hidden">
        <div className="text-sm">课堂作业</div>
        <div className="mt-5 text-base flex flex-col h-full overflow-auto">
          {selectedLesson?.lessonSchedule[0]?.isAssignmentsOpen &&
            selectedLesson?.assignments
              .map((a) => getFragmentData(HomePageLessonAssignmentFragmentDoc, a))
              .filter(({ assignmentType }) => assignmentType === 'ClassPractice')
              .map((assignment) => {
                // const assignmentName = parseAssignmentName(assignment.name);
                const selfSubmission = getFragmentData(
                  HomePageSelfSubmissionFragmentDoc,
                  assignment.selfSubmission,
                );

                return (
                  <div
                    key={assignment.assignmentId}
                    className="flex cursor-pointer mb-3 overflow-hidden flex-shrink-0"
                    onClick={() =>
                      setSelectedFunction({ type: 'assignment', id: assignment.assignmentId })
                    }
                  >
                    <div className="flex gap-1 items-center w-fit">
                      <div
                        className={cx(
                          'w-5 h-5 rounded-full text-white bg-[#bcbcbc] text-[10px] flex items-center justify-center font-semibold',
                          {
                            '!bg-[#1BA864]':
                              (selfSubmission?.artworks[0]?.medias.length ?? 0) >=
                              assignment.minQuantity,
                          },
                        )}
                      >
                        交
                      </div>
                      <div
                        className={cx(
                          'w-5 h-5 rounded-full text-white bg-[#bcbcbc] text-[10px] flex items-center justify-center font-semibold',
                          {
                            '!bg-[#1BA864]':
                              selfSubmission?.submissionStatus === SubmissionStatus.Completed,
                          },
                        )}
                        onClick={(e) => {
                          if (selfSubmission?.submissionStatus !== SubmissionStatus.Completed) {
                            return;
                          }
                          setSelectedFunction({
                            type: 'Transcript',
                            submissionId: selfSubmission?.submissionId,
                          });
                          e.stopPropagation();
                        }}
                      >
                        评
                      </div>
                    </div>
                    <div className="ml-2 whitespace-nowrap text-ellipsis overflow-hidden">
                      {assignment.name}
                    </div>
                  </div>
                );
              })}
          {/* {selectedLesson &&
            selectedLesson.assignments.length < 5 &&
            Array(5 - selectedLesson.assignments.length)
              .fill(null)
              .map((_, index) => {
                return (
                  <div className="flex-1" key={`assignment-course-placeholder-${index}`}></div>
                );
              })} */}
        </div>
      </div>
      {/* 家庭作业 */}
      <div className="bg-white p-3 col-span-2 overflow-hidden">
        <div className="text-sm">家庭作业</div>
        <div className="mt-5 text-base flex flex-col h-full overflow-auto">
          {selectedLesson?.lessonSchedule[0].isAssignmentsOpen &&
            selectedLesson?.assignments
              .map((a) => getFragmentData(HomePageLessonAssignmentFragmentDoc, a))
              .filter(({ assignmentType }) => assignmentType === 'Homework')
              .map((assignment) => {
                const selfSubmission = getFragmentData(
                  HomePageSelfSubmissionFragmentDoc,
                  assignment.selfSubmission,
                );

                return (
                  <div
                    key={assignment.assignmentId}
                    className="flex cursor-pointer mb-3 flex-shrink-0"
                    onClick={() =>
                      setSelectedFunction({ type: 'assignment', id: assignment.assignmentId })
                    }
                  >
                    <div className="flex gap-1 items-center">
                      <div
                        className={cx(
                          'w-5 h-5 rounded-full text-white bg-[#bcbcbc] text-[10px] flex items-center justify-center font-semibold',
                          {
                            '!bg-[#1BA864]':
                              (selfSubmission?.artworks[0]?.medias.length ?? 0) >=
                              assignment.minQuantity,
                          },
                        )}
                      >
                        交
                      </div>
                      <div
                        className={cx(
                          'w-5 h-5 rounded-full text-white bg-[#bcbcbc] text-[10px] flex items-center justify-center font-semibold',
                          {
                            '!bg-[#1BA864]':
                              selfSubmission?.submissionStatus === SubmissionStatus.Completed,
                          },
                        )}
                        onClick={(e) => {
                          if (selfSubmission?.submissionStatus !== SubmissionStatus.Completed) {
                            return;
                          }
                          setSelectedFunction({
                            type: 'Transcript',
                            submissionId: selfSubmission?.submissionId,
                          });
                          e.stopPropagation();
                        }}
                      >
                        评
                      </div>
                    </div>
                    <div className="ml-2 whitespace-nowrap text-ellipsis overflow-hidden">
                      {assignment.name}
                    </div>
                  </div>
                );
              })}
          {/* {selectedLesson &&
            selectedLesson.assignments.length < 5 &&
            Array(5 - selectedLesson.assignments.length)
              .fill(null)
              .map((_, index) => {
                return (
                  <div className="flex-1" key={`assignment-course-placeholder-${index}`}></div>
                );
              })} */}
        </div>
      </div>
      {/* 公告栏 */}
      <div className="info-board bg-white">
        <div className="w-full h-full flex flex-col px-3 pt-3">
          <div className="pb-2 bg-white">公告栏</div>
          {!loadingClassAnnouncementsData && classAnnouncementsData && (
            <div
              className="overflow-auto pb-6"
              dangerouslySetInnerHTML={{
                __html: classAnnouncementsData.classAnnouncements[0]?.text,
              }}
            ></div>
          )}
        </div>
      </div>
    </Div100vh>
  );
};
